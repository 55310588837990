<template>
    <div>
        <div class="header pb-6 d-flex">
            <!-- Mask -->
            <span class="mask bg-white opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="d-flex">
                <b-row>
                    <b-col sm="12" md="auto" class="mt-4">
                        <div
                            @click="$router.back()"
                            class="icon icon-shape rounded-circle shadow bg-primary text-white"
                        >
                            <i class="fa fa-arrow-left"></i>
                        </div>
                    </b-col>
                    <b-col sm="12" md="auto">
                        <!-- <b-row>
                            <b-col>
                                <p>
                                    37 Likes
                                    <i
                                        v-if="!liked"
                                        class="far fa-thumbs-up text-lg ml-1"
                                        @click="update_likes('like')"
                                    ></i>
                                    <i
                                        v-else
                                        class="fas fa-thumbs-up text-lg ml-1"
                                        @click="update_likes('unlike')"
                                    ></i>
                                </p>
                            </b-col>
                        </b-row> -->
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container fluid class="mt--5">
            <b-row>
                <!-- Main Col -->
                <b-col sm="12" md="8">
                    <!-- Detail Description -->
                    <b-row>
                        <Transition>
                            <b-col
                                class="d-flex align-items-center justify-content-center"
                                v-if="
                                    $apollo.queries.get_hackathon_idea_idea
                                        .loading
                                "
                            >
                                <h1>
                                    <i class="fas fa-spinner fa-spin ml-1"></i>
                                </h1>
                            </b-col>
                            <b-col v-else>
                                <b-card>
                                    <b-row>
                                        <b-col sm="auto"
                                            ><h1 class="text-primary">
                                                {{ idea.name }}
                                            </h1>
                                            <!-- <small>{{ idea.creator }}</small> -->
                                        </b-col>
                                        <!-- :loading="
                                                accept_invite_button.loading
                                            "
                                            :disabled="
                                                accept_invite_button.loading
                                            "
                                            :success="
                                                accept_invite_button.success
                                            " -->
                                        <!--                                         
                                        <b-col class="text-right">
                                            <router-link
                                                v-if="idea.is_creator"
                                                :to="{
                                                    name: 'HackathonRudIdea',
                                                    params: {
                                                        idea_id: idea.id,
                                                        hackathon_id:
                                                            hackathon.id,
                                                    },
                                                }"
                                            >
                                                <i
                                                    class="fas fa-edit text-black-50"
                                                ></i>
                                            </router-link>
                                        </b-col> -->
                                    </b-row>

                                    <b-row>
                                        <b-col>
                                            <h5>{{ idea.description }}</h5>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col>
                                            <div
                                                v-html="idea.detail_description"
                                            ></div>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                        </Transition>
                    </b-row>
                </b-col>
                <!-- Side bar -->
                <b-col sm="12" md="3">
                    <b-row>
                        <b-col>
                            <ApprovalComponent :approval_id="idea.approval.id" :show_history="true" />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
// Components
import Badge from "@/components/Badge.vue";
import HackathonTeamMembers from "@/views/Components/Hackathon/Team/TeamMembers.vue";
import ApprovalComponent from "@/views/Components/Approval/ApprovalComponent.vue";

// Queries
import {
    GET_HACKATHON_IDEA_IDEA_APPROVAL,
    GET_HACKATHON_PROFILE_FROM_USER_ID,
} from "@/graphql/queries";

// Mutations

// ORM
import { processIdeaRelayData, Idea } from "@/ORM/Hackathon/Idea.js";

export default {
    name: "HackathonApproveIdea",
    components: {
        Badge,
        HackathonTeamMembers,
        ApprovalComponent,
    },
    apollo: {
        get_hackathon_idea_idea: {
            query: GET_HACKATHON_IDEA_IDEA_APPROVAL,
            result(data) {
                this.idea = processIdeaRelayData(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    idea_id: this.idea.id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_idea_idea = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_idea_idea: null,
                get_hakcathon_profile: null,
            },
            id: null,
            idea: new Idea(),
            hackathon_profile: {
                id: null,
            },
            team: {
                id: null,
                member_count: 0,
                is_member: false,
                requested_to_join: false,
            },
            join_button: {
                loading: false,
                success: false,
            },
            hackathon: {
                id: null,
            },
        };
    },
    methods: {
        enable_query(query) {
            if (!query.skip) {
                query.refetch();
            } else {
                query.skip = false;
                query.start();
            }
        },

        get_id() {
            if (this.$route.params.idea_id) {
                this.idea.id = this.$route.params.idea_id;
                this.enable_query(this.$apollo.queries.get_hackathon_idea_idea);
            } else {
                this.idea.id = null;
            }
            if (this.$route.params.hackathon_id) {
                this.hackathon.id = this.$route.params.hackathon_id;
                this.enable_query(this.$apollo.queries.get_hackathon_idea_idea);
            } else {
                this.id = null;
            }
            // TODO: reroute to discover if no id is found.
        },
    },
    mounted() {
        this.get_id();
    },
};
</script>

<style></style>
