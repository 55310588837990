var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Transition',[(!_vm.approval_id)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()]),_c('Transition',[(_vm.approval_id)?_c('b-card',[_c('b-card-body',{staticClass:"p-0"},[_c('b-row',[_c('b-col',[_c('h4',{staticClass:"text-muted heading-small"},[_vm._v(" Approval Status ")])])],1),_c('b-row',[_c('b-col',[_c('badge',{attrs:{"rounded":true,"size":"md","type":_vm.state_badge_style_type(
                                    _vm.form.status.previous_selection.name
                                )}},[_vm._v(_vm._s(_vm.form.status.previous_selection.display_name)+" ")])],1)],1),_c('b-row',[_c('b-col',[_c('hr')])],1),_c('b-row',[_c('b-col',[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"approval_status_form_submit",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.on_form_submit)}}},[_c('b-row',[_c('b-col',[_c('base-input',{attrs:{"label":"Approval Status","error":_vm.form.status.error_message,"name":"Approval Status","rules":{ required: true },"is_loading":_vm.$apollo.loading ||
                                                _vm.form.is_loading}},[_c('el-select',{attrs:{"filterable":"","placeholder":"Select","disabled":_vm.$apollo.loading ||
                                                    _vm.form.is_loading},on:{"change":function($event){return _vm.handle_approval_state_change()}},model:{value:(
                                                    _vm.form.status.selection
                                                ),callback:function ($$v) {_vm.$set(_vm.form.status, "selection", $$v)},expression:"\n                                                    form.status.selection\n                                                "}},_vm._l((_vm.form
                                                        .status.options),function(option){return _c('el-option',{key:option.id,attrs:{"label":option.display_name,"value":option.id}})}),1),(
                                                    _vm.form.status
                                                        .error_message
                                                )?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.form.status .error_message)+" ")]):_vm._e()],1),_c('base-input',{attrs:{"label":"Message","rules":{
                                                required:
                                                    _vm.form.message_required,
                                            },"type":"textarea","name":"Message","rows":"5","is_loading":_vm.$apollo.loading ||
                                                _vm.form.is_loading},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('base-button',{staticClass:"mt-2",attrs:{"type":"primary","pill":true,"native-type":"submit","loading":_vm.form.is_loading,"success":_vm.form.save_button
                                                    .show_success,"disabled":_vm.form.is_loading}},[_vm._v("Save "+_vm._s(_vm.form.status.selection !== _vm.form.status .previous_selection.id ? "New Status" : "Comment")+" ")])],1)],1)],1)]}}],null,false,1723056514)})],1)],1),_c('b-row',{staticClass:"pt-3"},[_c('b-col',[_c('div',{staticClass:"approval-item-list",attrs:{"id":"approval-container"}},[_c('div',{staticClass:"content"},_vm._l((_vm.approval.state_updates),function(approval_state_update){return _c('approval-state-item',{key:approval_state_update.id,attrs:{"comment":approval_state_update.comment,"created_date_time":approval_state_update.created,"id":approval_state_update.id,"state_display_name":approval_state_update.new_state.display_name,"state_name":approval_state_update.new_state.name,"name":"approval"}})}),1)])])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }